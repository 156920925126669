<template>
  <v-card
    id="role-form"
    min-height="350"
  >
    <!-- actions -->
    <v-fab-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            color="secondary"
            dark
            absolute
            small
            top
            right
            fab
            class="mb-4 me-3"
            v-on="on"
            @click="$router.go(-1)"
          >
            <v-icon>
              {{ icons.mdiTableArrowLeft }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ t("tooltip.back_table") }}</span>
      </v-tooltip>
    </v-fab-transition>

    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
      color="secondary"
    >
      <v-tab
        v-for="tab in computedTabs"
        :key="tab.icon"
        color="secondary"
        :disabled="tab.disabled"
      >
        <v-icon
          v-if="!tab.hide"
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ t(`roles.${tab.title}`) }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item :kei="0">
        <role-tab-general
          :data-params="roleData"
          :tab="tab"
          :option="option"
          @changeTab="changeTab"
          @save="saveAction"
          @cancel="cancelAction"
        ></role-tab-general>
      </v-tab-item>

      <v-tab-item :kei="1">
        <role-tab-permission
          :data-params="roleData"
          :tab="tab"
          :option="option"
          @save="saveAction"
          @cancel="cancelAction"
        ></role-tab-permission>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
/* eslint-disable import/no-cycle */
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
  mdiTableArrowLeft,
  mdiKeyOutline,
} from '@mdi/js'
import {
  onMounted, ref, computed, watch,
} from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import { error, success } from '@core/utils/toasted'
import useSelectOptions from '@/@core/utils/useSelectOptions'
import { createRole, editRole, getRole } from '@api'

// tabs
import RoleTabGeneral from './RoleTabGeneral.vue'
import RoleTabPermission from './RoleTabPermission.vue'

export default {
  components: {
    RoleTabGeneral,
    RoleTabPermission,
  },
  setup() {
    const { t } = useUtils()
    const { route, router } = useRouter()
    const {
      configOrganization,
      configFacility,
    } = useSelectOptions()

    const tab = ref(0)
    const option = ref(1)
    const roleId = ref(null)
    const roleData = ref({})
    const postData = ref({
      description: null,
      name: null,
      permissions_int_ids: [],
      status: null,
    })
    const putData = ref({
      description: null,
      name: null,
      permissions_int_ids: [],
      status: null,
    })

    // tabs
    const tabsInit = ref([
      {
        title: 'general',
        icon: mdiAccountOutline,
        disabled: false,
        hide: false,
      },
      {
        title: 'permissions',
        icon: mdiKeyOutline,
        disabled: true,
        hide: false,
      },
    ])

    const computedTabs = computed({
      // eslint-disable-next-line no-return-assign
      set: val => (tabsInit.value = val),
      get: () => tabsInit.value,
    })

    // tabs
    const tabs = ref(tabsInit.value)

    const changeTab = (tabNumber, validForm) => {
      switch (tabNumber) {
        case 1:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[1].disabled = false
          }
          break
        default:
          break
      }

      tab.value = tabNumber
    }

    const saveAction = async data => {
      let resp = null
      if (option.value === 1) {
        postData.value = Object.assign(postData.value, {
          description: data.description,
          name: data.name,
          permissions_int_ids: data.permissions_int_ids,
          status: data.status,
        })

        resp = await createRole(postData.value)
      }

      if (option.value === 3) {
        putData.value = Object.assign(putData.value, {
          description: data.description,
          name: data.name,
          permissions_int_ids: data.permissions_int_ids,
          status: data.status,
        })
        resp = await editRole(putData.value, data.id)
      }

      if (!option.value) {
        router.go(-1)
      }

      if (resp.ok) {
        router.go(-1)
        success(resp.message)
      } else {
        error(resp.message.text)
      }
    }

    const cancelAction = () => {
      tab.value = 0
      tabs.value[1].disabled = true
      roleData.value = {}
    }

    const goBack = () => {
      router.replace({
        name: 'views-role-list',
      })
    }

    watch([configOrganization, configFacility], () => {
      goBack()
    })

    onMounted(async () => {
      option.value = route.value.params.option
      roleId.value = route.value.params.id

      if (!option.value) goBack()

      if (option.value && roleId.value) {
        const resp = await getRole(roleId.value)
        if (resp.ok) {
          roleData.value = resp.data
        } else goBack()
      } else option.value = 1

      if (option.value !== 1) {
        tabs.value.forEach(e => {
          e.disabled = false
        })
      }
    })

    return {
      tab,
      tabs,
      tabsInit,
      computedTabs,
      option,
      roleId,
      roleData,
      postData,
      putData,

      changeTab,
      saveAction,
      cancelAction,

      // i18n
      t,

      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
        mdiTableArrowLeft,
        mdiKeyOutline,
      },
    }
  },
}
</script>
